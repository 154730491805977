var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSimpleTable',{staticClass:"elevation-2 width-100-percent"},[_c('thead',[_c('tr',{staticClass:"titles-thead"},[_c('th',{staticClass:"text-left city-column"},[_c('span',[_vm._v("Город")])]),_c('th',{staticClass:"text-left"},[_c('span',[_vm._v("Название заведения")])]),_c('th',{staticClass:"text-center delivery-service-column"},[_c('span',[_vm._v("Количество правок")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Менеджер")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Статус ресторана")])]),_c('th')]),_c('tr',{staticClass:"search-thead"},[_c('th',{staticClass:"py-3"},[_c('VSelect',{attrs:{"background-color":"#5B5B5B","clearable":"","dark":"","dense":"","hide-details":"","item-text":"title","item-value":"title","items":_vm.cities,"label":"Все","outlined":"","prepend-inner-icon":"mdi-magnify","solo":""},model:{value:(_vm.restaurantsFilter.city),callback:function ($$v) {_vm.$set(_vm.restaurantsFilter, "city", $$v)},expression:"restaurantsFilter.city"}})],1),_c('th',[_c('VTextField',{attrs:{"background-color":"#5B5B5B","clearable":"","dark":"","dense":"","hide-details":"","outlined":"","prepend-inner-icon":"mdi-magnify","solo":""},model:{value:(_vm.restaurantsFilter.title),callback:function ($$v) {_vm.$set(_vm.restaurantsFilter, "title", $$v)},expression:"restaurantsFilter.title"}})],1),_c('th'),_c('th'),_c('th'),_c('th')])]),_c('tbody',_vm._l((_vm.restaurants),function(restaurant){return _c('tr',{key:restaurant.restaurantId,class:{
        'red lighten-4': restaurant.status==='inProcess',
        'grey lighten-2': restaurant.status==='inactive'
      }},[_c('td',[_c('span',[_vm._v(_vm._s(restaurant.city))])]),_c('td',[_c('span',[_vm._v(_vm._s(restaurant.title))])]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(restaurant.count))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(restaurant.manager)+" ")]),_c('td',{staticClass:"text-center",class:restaurant.state === 'active'
            ? 'green--text'
            : ['filling_content', 'on_automation'].includes(restaurant.state)
              ? 'grey--text'
              : 'error--text'},[_vm._v(" "+_vm._s(_vm.translateState(restaurant.state))+" ")]),_c('td',{staticClass:"text-center"},[_c('a',{on:{"click":function($event){return _vm.$emit('restaurant-selected', restaurant.pk)}}},[_c('VIcon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-chevron-right")])],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }