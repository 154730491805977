class ModerationRestaurantsList {
  constructor(restaurant) {
    this.pk = restaurant.id || '';
    this.city = restaurant.city || '';
    this.title = restaurant.title || '';
    this.count = restaurant.count >= 0 ? restaurant.count : 0;
    this.manager = restaurant.manager || '';
    this.state = restaurant.state || '';
  }
}

export const serializeModerationRestaurant = (restaurant) => new ModerationRestaurantsList(
  restaurant,
);

export const serializeModerationRestaurantsList = (restaurants) => restaurants
  .map(serializeModerationRestaurant);
