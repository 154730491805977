<template>
  <VCol class="d-flex flex-wrap" cols="12">
    <VCol class="d-flex flex-wrap align-center" cols="12">
      <span class="page_title pr-4 align-center">Менеджер</span>
      <div class="width-30-percent">
        <form class="d-flex" @submit.prevent="filterRestaurants">
          <VTextField
            append-icon="mdi-account-circle-outline"
            autofocus
            background-color="#F2F2F2"
            class="pl-3"
            color="black"
            dense
            hide-details
            outlined
            placeholder="Менеджер"
            :value="restaurantsFilter.manager"
            @change="selectManager"
          />
        </form>
      </div>
      <ModerationRestaurantsTable
        class="mt-4"
        :filter="restaurantsFilter"
        :restaurants="restaurants"
        @filter-changed="setFilter"
        @restaurant-selected="reroute"
      />
    </VCol>
    <div class="paginationButtons mr-0 ml-auto">
      <VBtn
        class="ma-2"
        color="success"
        :disabled="!restaurantListHasPrevPage()"
        tile
        @click="fetchPrevRestaurantPage"
      >
        <VIcon left>
          mdi-chevron-left
        </VIcon> Предыдущие
      </VBtn>
      <VBtn
        class="ma-2"
        color="success"
        :disabled="!restaurantListHasNextPage()"
        tile
        @click="fetchNextRestaurantPage"
      >
        Следующие <VIcon right>
          mdi-chevron-right
        </VIcon>
      </VBtn>
    </div>
  </VCol>
</template>

<script>
import { serializeModerationRestaurantsList } from '@/serializers/moderationRestaurantsListSerializer';
import ModerationRestaurantsTable from '@/components/ModerationRestaurantsTable.vue';
import { fetchModerationRestaurantsList } from '@/api/api';

export default {
  components: {
    ModerationRestaurantsTable,
  },
  data() {
    return {
      restaurantsFilter: {
        title: '',
        manager: '',
        city: '',
      },
      restaurants: [],
      currentPage: 1,
      totalPage: 1,
    };
  },
  created() {
    Object.keys(this.$route.query).forEach((key) => {
      this.restaurantsFilter[key] = this.$route.query[key];
    });
    this.fetchListOfRestaurants();
  },
  methods: {
    reroute(pk) {
      this.$router.push({
        name: 'moderation-items',
        params: { pk },
        query: {
          ...this.createQueryParams(),
        },
      });
    },
    createQueryParams() {
      const query = {};
      Object.keys(this.restaurantsFilter)
        .forEach((key) => {
          query[key] = this.restaurantsFilter[key] ? this.restaurantsFilter[key] : undefined;
        });
      return query;
    },
    selectManager(manager) {
      this.restaurantsFilter.manager = manager;
      this.filterRestaurants();
    },
    setFilter(filterValues) {
      Object.keys(filterValues).forEach((key) => {
        this.restaurantsFilter[key] = filterValues[key];
      });
      this.filterRestaurants();
    },
    restaurantListHasNextPage() {
      return this.currentPage !== this.totalPage;
    },
    restaurantListHasPrevPage() {
      return this.currentPage !== 1;
    },
    fetchNextRestaurantPage() {
      this.currentPage += 1;
      this.fetchListOfRestaurants();
    },
    fetchPrevRestaurantPage() {
      this.currentPage -= 1;
      this.fetchListOfRestaurants();
    },
    filterRestaurants() {
      this.restaurantsFilter.offset = 0;
      this.fetchListOfRestaurants();
    },
    fetchListOfRestaurants() {
      const payload = {
        city: this.restaurantsFilter.city || undefined,
        title: this.restaurantsFilter.title || undefined,
        manager_email: this.restaurantsFilter.manager || undefined,
        // limit: 5, // 5 limits for testing
        page: this.currentPage,
      };
      fetchModerationRestaurantsList(payload).then((result) => {
        this.restaurants = serializeModerationRestaurantsList(result.items);
        this.totalPage = result.total_page;
        this.currentPage = result.current_page;
      });
    },
  },
  watch: {
    restaurantsFilter: {
      deep: true,
      handler() {
        this.$router.push({ query: this.createQueryParams() });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.paginationButtons {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
</style>
