<template>
  <VSimpleTable class="elevation-2 width-100-percent">
    <thead>
      <tr class="titles-thead">
        <th class="text-left city-column">
          <span>Город</span>
        </th>
        <th class="text-left">
          <span>Название заведения</span>
        </th>
        <th class="text-center delivery-service-column">
          <span>Количество правок</span>
        </th>
        <th class="text-center">
          <span>Менеджер</span>
        </th>
        <th class="text-center">
          <span>Статус ресторана</span>
        </th>
        <th></th>
      </tr>
      <tr class="search-thead">
        <th class="py-3">
          <VSelect
            v-model="restaurantsFilter.city"
            background-color="#5B5B5B"
            clearable
            dark
            dense
            hide-details
            item-text="title"
            item-value="title"
            :items="cities"
            label="Все"
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
          />
        </th>
        <th>
          <VTextField
            v-model="restaurantsFilter.title"
            background-color="#5B5B5B"
            clearable
            dark
            dense
            hide-details
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="restaurant in restaurants"
        :key="restaurant.restaurantId"
        :class="{
          'red lighten-4': restaurant.status==='inProcess',
          'grey lighten-2': restaurant.status==='inactive'
        }"
      >
        <td><span>{{ restaurant.city }}</span></td>
        <td><span>{{ restaurant.title }}</span></td>
        <td class="text-center">
          <span>{{ restaurant.count }}</span>
        </td>
        <td class="text-center">
          {{ restaurant.manager }}
        </td>
        <td
          class="text-center"
          :class="
            restaurant.state === 'active'
              ? 'green--text'
              : ['filling_content', 'on_automation'].includes(restaurant.state)
                ? 'grey--text'
                : 'error--text'
          "
        >
          {{ translateState(restaurant.state) }}
        </td>
        <td class="text-center">
          <a @click="$emit('restaurant-selected', restaurant.pk)">
            <VIcon color="black" large>mdi-chevron-right</VIcon>
          </a>
        </td>
      </tr>
    </tbody>
  </VSimpleTable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { restaurantStates } from '@/conditions/states';

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    restaurants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['restaurant-selected', 'filter-changed'],
  data() {
    return {
      restaurantsFilter: {
        title: '',
        city: '',
      },
      restaurantStates,
    };
  },
  computed: {
    ...mapGetters(['cities']),
  },
  created() {
    this.getCities();
    Object.keys(this.$route.query).forEach((key) => {
      this.restaurantsFilter[key] = this.$route.query[key];
    });
  },
  methods: {
    ...mapActions(['getCities']),
    translateState(state) {
      return restaurantStates.filter((el) => el.value === state)[0].text;
    },
  },
  watch: {
    restaurantsFilter: {
      deep: true,
      handler(changedData) {
        this.$emit('filter-changed', changedData);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/page.scss";

.titles-thead th {
  color: $default_dark_gray_color !important;
  background-color: white;
}
.search-thead th {
  color: white !important;
  background-color: $default_gray_color;
}
.city-column {
  width: 200px;
}
a {
  text-decoration: none;
}
</style>
